import Cookies from 'js-cookie';
import {cleanUrl} from '../helpers/clean-url';
import {isEmpty} from '../helpers/is-empty';
import {getRootDomain} from '../helpers/get-root-domain';
import {secureLocalStorage} from '../helpers/secure-local-storage';
import {Base64} from 'js-base64';

const KEY = 'reg';

const setValue = (key, value) => {
  secureLocalStorage.setItem(key, value);

  Cookies.set(key, value, {
    domain: getRootDomain(),
  });
};

// Generates a unique code of specified length
// Uses crypto.getRandomValues() when available, falls back to Math.random()
// Includes a timestamp component for additional uniqueness
const makeUniqueCode = (length) => {
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const timestamp = Date.now().toString(36);
  let text = '';

  // Generates a random integer between min (inclusive) and max (exclusive)
  // Uses crypto for better randomness when available, falls back to Math.random()
  const getRandomInt = (min, max) => {
    if (typeof crypto !== 'undefined' && crypto.getRandomValues) {
      const range = max - min;
      const bytesNeeded = Math.ceil(Math.log2(range) / 8);
      const maxValue = Math.pow(256, bytesNeeded);
      const array = new Uint8Array(bytesNeeded);

      let randomInt;
      do {
        crypto.getRandomValues(array);
        randomInt = 0;
        for (let i = 0; i < bytesNeeded; i++) {
          randomInt += array[i] * Math.pow(256, i);
        }
      } while (randomInt >= maxValue - (maxValue % range));

      return min + (randomInt % range);
    } else {
      return Math.floor(Math.random() * (max - min)) + min;
    }
  };

  for (let i = 0; i < length - timestamp.length; i++) {
    text += possible.charAt(getRandomInt(0, possible.length));
  }

  return timestamp + text;
};

const generatePayload = () => {
  return JSON.stringify({
    landingPage: cleanUrl(location.href),
    referrer: cleanUrl(document.referrer),
    firstHit: (new Date()).toUTCString(),
    uniqueCode: makeUniqueCode(24),
  });
};

export const createRegistrationCookies = () => {
  const cookieValue = Cookies.get(KEY);
  const lsValue = secureLocalStorage.getItem(KEY);

  // Use both storage options (cookies and localStorage) to store the data.
  // This way even if the cookies or the LS gets emptied, we can still fetch
  // the data from the other source.
  // This helps us catch bad actors creating multiple accounts with different
  // emails and cleaning their cookies every time.

  if (isEmpty(lsValue) && isEmpty(cookieValue)) {
    // Generate new cookie
    const payload = generatePayload();
    setValue(KEY, payload);
    return payload;
  } else if (!isEmpty(lsValue) && isEmpty(cookieValue)) {
    // Restore cookie into cookie
    setValue(KEY, lsValue);
    return lsValue;
  } else if (isEmpty(lsValue) && !isEmpty(cookieValue)) {
    // Restore cookie into localStorage
    setValue(KEY, cookieValue);
    return cookieValue;
  } else {
    return cookieValue;
  }
};

export const appendCookieDataToLinks = (payload) => {
  const encodedPayload = Base64.encode(payload);

  // Append payload to the querystring parameter "se" of all links pointing to
  // the app (https://app.redokun.com/login and https://app.redokun.com/registration)
  const appLinks = document.querySelectorAll(
    'a[href^="https://app.redokun.com"]');
  appLinks.forEach((link) => {

    link.addEventListener('click', event => {
      const currentUrl = new URL(link.getAttribute('href'));
      currentUrl.searchParams.set('se', encodedPayload);

      link.setAttribute('href', currentUrl.toString());
    });
  });
};


'use strict';

import * as Sentry from '@sentry/browser';
import {
  appendCookieDataToLinks,
  createRegistrationCookies,
} from './components/marketing-cookie';

/*
 * Marketing cookies
 */

try {
  const payload = createRegistrationCookies();
  appendCookieDataToLinks(payload);
} catch (err) {
  Sentry.init({
    dsn: 'https://433c58f3c9234fd6b0d9214b6b3fbf88@o180217.ingest.sentry.io/6707908',
  });

  Sentry.captureException(err);
}

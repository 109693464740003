// Most modern browsers have localStorage, but we want to be sure that this
// piece of code does not break the whole application if for whatever reason
// it is not accessible (eg. browser extensions, security policy, ancient browser)
export const secureLocalStorage = {
  setItem: (key, value) => {
    try {
      localStorage.setItem(key, value);
      return true;
    } catch (e) {
      console.warn('Failed to call localStorage.setItem()');
      return false;
    }
  },
  getItem: (key) => {
    try {
      return localStorage.getItem(key);
    } catch (e) {
      console.warn('Failed to call localStorage.getItem()');
      return null;
    }
  },
};
